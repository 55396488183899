import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Work from '@compositions/Work'

const BlogPage = () => {
  return (
    <Layout>
      <Work />
    </Layout>
  )
}

export default BlogPage
