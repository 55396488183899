import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

// components
import Hr from '@components/Hr'

import colors from '@styles/js/colors'

const Project = styled(
  ({ data: { title, workPageFields, featuredImage }, fluid, className }) => (
    <div className={className}>
      {workPageFields.workProjectLink && (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          href={workPageFields.workProjectLink}
          target="_blank"
          rel="noreferrer"
          className="work-link"
        />
      )}
      <Img
        fluid={fluid}
        alt={
          featuredImage.altText
            ? featuredImage.altText
            : workPageFields.workAltText
        }
        className="img"
      />
      <div className="content p-4 bg-white w-100">
        <h3 className="mb-3 text-black">{title}</h3>
        <Hr color={colors.saffron} />
      </div>
    </div>
  )
)`
  .img {
    height: auto;
    width: 100%;
  }

  .work-link {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  img {
    -webkit-transition: 0.4s ease !important;
    transition: 0.4s ease !important;
  }

  &:hover {
    img {
      -webkit-transform: scale(1.08);
      transform: scale(1.08);
    }
    .content {
      hr {
        background-color: #2b58ca;
      }
    }
  }

  .content {
    max-width: 80%;
    transform: translateY(-50px);
    transition: all 100ms linear;

    h3 {
      font-size: 24px;
      line-height: 36px;
    }

    h6 {
      font-size: 20px;

      @media (min-width: 768px) {
        font-size: 28px;
      }
    }

    hr {
      height: 3px;
      -webkit-transition: 0.4s ease;
      transition: 0.4s ease;
    }
  }
`

export default Project
