import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

const BackgroundWord = styled(({ className, children, img }) => (
  <div className={className}>{children}</div>
))`
  background-image: url('${props => props.img.childImageSharp.fluid.src}');
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 1200px;
  width: 100%;
`
BackgroundWord.propTypes = {
  img: object.isRequired
}

BackgroundWord.defaultProps = {
  img: {}
}

export default BackgroundWord
