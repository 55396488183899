import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  align-items: center;
  background-color: transparent;
  color: inherit !important;
  display: flex;
  font-size: 20px;
  text-decoration: none !important;

  svg{
    margin-left: 30px;
  }
`

export default Link
