import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

const Image = styled(({ fluid, alt, className }) => (
  <div className={className}>
    <Img fluid={fluid} alt={alt} />
  </div>
))`
  margin: -5rem auto 0 auto;
  max-width: 360px;

  @media (min-width: 768px) {
    margin: 0;
  }
`

Image.propTypes = {
  fluid: object.isRequired
}

Image.defaultProps = {
  fluid: {}
}

export default Image
