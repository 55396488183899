import styled from '@emotion/styled'

const Text = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 0.02em;

  @media (min-width: 1440px) {
    font-size: 48px;
    font-weight: 300;
    line-height: 56px;
  }
`
export default Text
