import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FormBox from './components/FormBox'

const ProjectForm = styled(({ data, className }) => {
  return (
    <div className={`${className}`}>
      <div className="project-form">
        <Container>
          <Row>
            <Col md="6">
              <div
                className="project-form-image d-md-none"
                style={{
                  backgroundImage: 'url(' + data.image.mobile.sourceUrl + ')'
                }}
              ></div>
              <div
                className="project-form-image d-none d-md-block d-xl-none"
                style={{
                  backgroundImage: 'url(' + data.image.tablet.sourceUrl + ')'
                }}
              ></div>
              <div
                className="project-form-image d-none d-xl-block"
                style={{
                  backgroundImage: 'url(' + data.image.desktop.sourceUrl + ')'
                }}
              ></div>
            </Col>
            <Col md="6">
              <h6>
                <span>{data.title}</span>
              </h6>
              <FormBox submit="Let’s chat" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
})`
  .project-form {
    margin-bottom: 80px;

    .project-form-image {
      background-size: cover;
      background-position: center top;
      display: block;
      height: 186px;
      position: relative;
      margin-bottom: 31px;

      @media (min-width: 768px) {
        height: 392px;
        margin-bottom: 0;
        margin-top: 35px;
        margin-left: auto;
        width: calc(100% - 35px);
      }

      @media (min-width: 1440px) {
        height: 280px;
      }

      &:before {
        background: #e2f2e2;
        content: '';
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        display: block;
        height: 173px;
        top: -24px;
        left: -24px;
        position: absolute;
        z-index: -1;
        width: 173px;

        @media (min-width: 768px) {
          height: 324px;
          top: -35px;
          left: -35px;
          width: 324px;
        }

        @media (min-width: 1440px) {
          height: 324px;
          top: -35px;
          left: -35px;
          width: 324px;
        }
      }
    }

    input {
      border: 1px solid #c5c5c5;
      margin-bottom: 16px;

      &.is-valid {
        background: none !important;
      }

      &.error {
        border: 1px solid #e10506;
        margin-bottom: 8px;
      }
    }

    button {
      background: #f5c141;
      color: #000;
      display: block;
      text-transform: uppercase;
      margin-top: 16px;
      width: 100%;

      @media (min-width: 1440px) {
        margin-top: 0;
      }
    }

    .invalid-helper {
      color: #e10506;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      margin-bottom: 16px;
      margin-top: 0;
    }

    h6 {
      align-items: center;
      color: #000000;
      display: flex;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.03em;

      @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
        margin-top: 35px;
      }

      &:after {
        background: #6dbf70;
        content: '';
        display: inline-block;
        height: 2px;
        margin-left: 16px;
        width: 50px;
      }
    }
  }
`

ProjectForm.propTypes = {
  data: object.isRequired
}

ProjectForm.defaultProps = {
  data: {}
}

export default ProjectForm
