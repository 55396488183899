import { useStaticQuery, graphql } from 'gatsby'

export const workContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "214", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Herodata {
                image {
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile{
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Worksdata {
                workcategories {
                  slug
                }
                workchoices {
                  ... on WPGraphQL_Work {
                    title
                    featuredImage {
                      altText
                      sourceUrl
                      mediaItemId
                      modified
                      imageFile{
                        childImageSharp {
                          fluid(maxWidth: 690, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                    workCategories {
                      nodes {
                        slug
                      }
                    }
                    workPageFields {
                      workAltText
                      workProjectLink
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Quotebannerdata {
                text
                linktext
                linkurl
                image {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile{
                    childImageSharp {
                      fluid(maxWidth: 360, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                alt
              }
            }
          }
        }
        themeSettings {
          settingFields {
            project {
              submitbutton
              title
              image {
                mobile {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                tablet {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
                desktop {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.page.AllPageElements.pageelements[0],
    data.wpgraphql.page.AllPageElements.pageelements[1],
    data.wpgraphql.page.AllPageElements.pageelements[2],
    data.wpgraphql.page.AllPageElements.pageelements[3],
    data.wpgraphql.themeSettings.settingFields.project
  ]
}
