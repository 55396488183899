import React from 'react'
import { array, func, string } from 'prop-types'
import styled from '@emotion/styled'

// utils
import colors from '@styles/js/colors'

const Filters = styled(({ data, onClick, selected, className }) => {
  return (
    <div className={`${className} mx-auto`}>
      <ul className="my-0 p-0">
        {data.map((item, index) => (
          <li className="d-inline-block" key={index}>
            <button
              className={`filter d-block text-capitalize ${selected && item.slug === selected && 'active'}`}
              key={item.slug}
              onClick={() => onClick(item.slug)}
              onKeyDown={() => onClick(item.slug)}
            >
              {item.slug}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
})`
  ul {
    list-style: none;
  }

  .filter {
    background: none;
    border: 0;
    color: ${colors.white} !important;
    cursor: pointer;
    font-size: 20px;
    margin-right: 20px;
    padding: 0;

    &.active {
      color: ${colors.black} !important;
    }

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }
`

Filters.propTypes = {
  data: array.isRequired,
  selected: string,
  onClick: func
}

Filters.defaultProps = {
  data: [],
  selected: string,
  onClick: () => null
}

export default Filters
