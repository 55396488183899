import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '@components/SEO'
import QuoteBanner from './components/QuoteBanner'
import Background from './components/Background'
import Filters from './components/Filters'
import Project from './components/Project'
import ProjectForm from '@components/ProjectForm'

// graphql
import { workContent } from './modules/graphql'

// utils
import colors from '@styles/js/colors'

const Work = styled(({ className }) => {
  const [selected, setSelected] = useState('all')
  const [hasChanged, setHasChanged] = useState(true)

  const seoData = workContent()[0]
  const heroData = workContent()[1]
  const worksData = workContent()[2]
  const quoteBannerData = workContent()[3]
  const projectFormData = workContent()[4]

  const handleClick = key => {
    setSelected(key)
    setHasChanged(false)
  }

  useEffect(() => {
    setHasChanged(true)
  }, [hasChanged])

  return (
    <div className={`${className}`}>
      <SEO title={seoData.title} description={seoData.description} />
      <Background img={heroData.image.imageFile}>
        <div className="my-5 d-inline-block w-100">
          <Container>
            <Row className="align-items-center">
              <Col sm={12}>
                <h1 className="mb-0 text-black">Our Work</h1>
              </Col>
              <Col sm={12} className="d-none d-md-block">
                <div className="mb-5 mt-3 mt-sm-3">
                  <Filters
                    data={worksData.workcategories}
                    selected={selected}
                    onClick={handleClick}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={`mb-md-3 d-inline-block w-100`}>
          <div className="mt-0 d-inline-block w-100">
            <Container>
              <Row>
                {hasChanged &&
                  worksData.workchoices.map((item, index) => {
                    const workCats = item.workCategories.nodes.map(cat => {
                      return cat.slug
                    })
                    return workCats.includes(selected) || selected === 'all' ? (
                      <Col lg={6} className="project" key={index}>
                        <Project
                          data={item}
                          fluid={
                            item.featuredImage.imageFile.childImageSharp.fluid
                          }
                        />
                        {/*
                        <motion.div
                          initial={{ opacity: 0, y: 25 }}
                          transition={{ duration: 0.25 }}
                          animate={{ scale: [0.95, 1], opacity: 1, y: -25 }}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 1.05 }}
                        ></motion.div>
                        */}
                      </Col>
                    ) : null
                  })}
              </Row>
            </Container>
          </div>
        </div>
      </Background>
      <div className="quote-wrap w-100">
        <QuoteBanner
          data={quoteBannerData}
          fluid={quoteBannerData.image.imageFile.childImageSharp.fluid}
        />
      </div>
      <ProjectForm data={projectFormData} />
    </div>
  )
})`

  .quote-wrap {
    margin-bottom: 69px;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }
  }

  .project {
    transition: all 100ms linear;

    &:nth-of-type(even) {
      margin-top: 0;

      @media (min-width: 992px) {
        margin-top: -145px;
      }
    }
    &:hover {
      .content {
        //background: ${colors.greenLight} !important;
      }
    }
  }
`

export default Work
